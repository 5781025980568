/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../key-item-tracker/key-item-tracker.component.ngfactory";
import * as i2 from "../key-item-tracker/key-item-tracker.component";
import * as i3 from "../key-item.service";
import * as i4 from "./key-item-picker.component";
import * as i5 from "@angular/material/dialog";
import * as i6 from "./key-item-picker.component.scss.shim.ngstyle";
import * as i7 from "@angular/common";
import * as i8 from "../location.service";
var styles_KeyItemPickerDialogComponent = [];
var RenderType_KeyItemPickerDialogComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_KeyItemPickerDialogComponent, data: {} });
export { RenderType_KeyItemPickerDialogComponent as RenderType_KeyItemPickerDialogComponent };
export function View_KeyItemPickerDialogComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-key-item-tracker", [["extraActions", "true"]], null, [[null, "select"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("select" === en)) {
        var pd_0 = (_co.keyItemEvent($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i1.View_KeyItemTrackerComponent_0, i1.RenderType_KeyItemTrackerComponent)), i0.ɵdid(1, 114688, null, 0, i2.KeyItemTrackerComponent, [i3.KeyItemService], { extraActions: [0, "extraActions"] }, { select: "select" })], function (_ck, _v) { var currVal_0 = "true"; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_KeyItemPickerDialogComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-key-item-picker-dialog", [], null, null, null, View_KeyItemPickerDialogComponent_0, RenderType_KeyItemPickerDialogComponent)), i0.ɵdid(1, 49152, null, 0, i4.KeyItemPickerDialogComponent, [i5.MatDialogRef], null, null)], null, null); }
var KeyItemPickerDialogComponentNgFactory = i0.ɵccf("app-key-item-picker-dialog", i4.KeyItemPickerDialogComponent, View_KeyItemPickerDialogComponent_Host_0, {}, {}, []);
export { KeyItemPickerDialogComponentNgFactory as KeyItemPickerDialogComponentNgFactory };
var styles_KeyItemPickerComponent = [i6.styles];
var RenderType_KeyItemPickerComponent = i0.ɵcrt({ encapsulation: 0, styles: styles_KeyItemPickerComponent, data: {} });
export { RenderType_KeyItemPickerComponent as RenderType_KeyItemPickerComponent };
export function View_KeyItemPickerComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "img", [["class", "icon"]], [[8, "src", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.pick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵpid(131072, i7.AsyncPipe, [i0.ChangeDetectorRef])], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵunv(_v, 0, 0, i0.ɵnov(_v, 1).transform(_co.img$)); _ck(_v, 0, 0, currVal_0); }); }
export function View_KeyItemPickerComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-key-item-picker", [], null, null, null, View_KeyItemPickerComponent_0, RenderType_KeyItemPickerComponent)), i0.ɵdid(1, 114688, null, 0, i4.KeyItemPickerComponent, [i5.MatDialog, i8.LocationService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var KeyItemPickerComponentNgFactory = i0.ɵccf("app-key-item-picker", i4.KeyItemPickerComponent, View_KeyItemPickerComponent_Host_0, { locId$: "locId$", type: "type", slot: "slot" }, {}, []);
export { KeyItemPickerComponentNgFactory as KeyItemPickerComponentNgFactory };
