/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./poi.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./poi.component";
import * as i4 from "../location.service";
import * as i5 from "../state.service";
var styles_PoiComponent = [i0.styles];
var RenderType_PoiComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_PoiComponent, data: {} });
export { RenderType_PoiComponent as RenderType_PoiComponent };
function View_PoiComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [], null, null, null, null, null)), i1.ɵdid(1, 278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { ngClass: [0, "ngClass"] }, null), i1.ɵpad(2, 2), (_l()(), i1.ɵeld(3, 0, null, null, 2, "img", [], [[2, "disabled", null], [8, "src", 4]], null, null, null, null)), i1.ɵdid(4, 278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { ngClass: [0, "ngClass"] }, null), i1.ɵpad(5, 2)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _ck(_v, 2, 0, "icon-container", _co.size); _ck(_v, 1, 0, currVal_0); var currVal_3 = _ck(_v, 5, 0, "icon", _co.size); _ck(_v, 4, 0, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = !_co.enabled; var currVal_2 = _co.img(); _ck(_v, 3, 0, currVal_1, currVal_2); }); }
export function View_PoiComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_PoiComponent_1)), i1.ɵdid(1, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.poi; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_PoiComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-poi", [], null, null, null, View_PoiComponent_0, RenderType_PoiComponent)), i1.ɵdid(1, 114688, null, 0, i3.PoiComponent, [i4.LocationService, i5.StateService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var PoiComponentNgFactory = i1.ɵccf("app-poi", i3.PoiComponent, View_PoiComponent_Host_0, { locId: "locId", poiIndex: "poiIndex", size: "size" }, {}, []);
export { PoiComponentNgFactory as PoiComponentNgFactory };
