import { HttpClient } from "@angular/common/http";
import { map } from "rxjs/operators";
import { IndexedData } from "./indexed-data";
import { StateService } from "./state.service";
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "./state.service";
var BossService = /** @class */ (function () {
    function BossService(http, stateService) {
        this.http = http;
        this.stateService = stateService;
        this.bosses = (new IndexedData(this.http, "./assets/data/bosses.json"));
    }
    BossService.prototype.getBosses = function () {
        return this.bosses.data$;
    };
    BossService.prototype.getBossOrder = function () {
        return this.bosses.order$;
    };
    BossService.prototype.getBossesFound = function () {
        return this.stateService.getState().pipe(map(function (state) { return state.bosses; }));
    };
    BossService.ngInjectableDef = i0.defineInjectable({ factory: function BossService_Factory() { return new BossService(i0.inject(i1.HttpClient), i0.inject(i2.StateService)); }, token: BossService, providedIn: "root" });
    return BossService;
}());
export { BossService };
