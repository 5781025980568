import { OnInit } from "@angular/core";
import { LocationService } from "../location.service";
import { ObservableData } from "../observable-data";
var LocationSummaryComponent = /** @class */ (function () {
    function LocationSummaryComponent(locationService) {
        this.locationService = locationService;
        this._locId = new ObservableData(undefined);
    }
    Object.defineProperty(LocationSummaryComponent.prototype, "locId", {
        set: function (id) {
            this._locId.nextData(id);
        },
        enumerable: true,
        configurable: true
    });
    LocationSummaryComponent.prototype.ngOnInit = function () {
        this.loc$ = this.locationService.getLocation(this._locId.data$);
        this.state$ = this.locationService.getLocationState(this.loc$);
    };
    return LocationSummaryComponent;
}());
export { LocationSummaryComponent };
