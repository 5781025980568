<h1 mat-dialog-title>Shops</h1>
<div mat-dialog-content>
    <div fxLayout='column' *ngxIfNotEmpty="locs">
    <mat-checkbox *ngFor="let id of locOrder" [(ngModel)]="selected[id]">{{locs[id].name}}</mat-checkbox>
    </div>
</div>

<div mat-dialog-actions>
        <button mat-button (click)="onNoClick()">No Thanks</button>
    <button mat-button [mat-dialog-close]="selectedIds()" cdkFocusInitial>Ok</button>
</div>