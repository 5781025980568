/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./character-tracker.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../character/character.component.ngfactory";
import * as i3 from "../character/character.component";
import * as i4 from "@angular/common";
import * as i5 from "./character-tracker.component";
import * as i6 from "../character.service";
var styles_CharacterTrackerComponent = [i0.styles];
var RenderType_CharacterTrackerComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_CharacterTrackerComponent, data: {} });
export { RenderType_CharacterTrackerComponent as RenderType_CharacterTrackerComponent };
function View_CharacterTrackerComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "char"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.selectCharacter(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "app-character", [], null, null, null, i2.View_CharacterComponent_0, i2.RenderType_CharacterComponent)), i1.ɵdid(2, 114688, null, 0, i3.CharacterComponent, [], { char: [0, "char"], state: [1, "state"] }, null), i1.ɵpid(131072, i4.AsyncPipe, [i1.ChangeDetectorRef]), i1.ɵpid(131072, i4.AsyncPipe, [i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 2, 0, i1.ɵnov(_v, 3).transform(_co.chars$))[_v.context.$implicit]; var currVal_1 = i1.ɵunv(_v, 2, 1, i1.ɵnov(_v, 4).transform(_co.charsFound$))[_v.context.$implicit]; _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
function View_CharacterTrackerComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "tracker"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 2, null, View_CharacterTrackerComponent_2)), i1.ɵdid(2, 278528, null, 0, i4.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), i1.ɵpid(131072, i4.AsyncPipe, [i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 2, 0, i1.ɵnov(_v, 3).transform(_co.charOrder$)); _ck(_v, 2, 0, currVal_0); }, null); }
export function View_CharacterTrackerComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 4, null, View_CharacterTrackerComponent_1)), i1.ɵdid(1, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), i1.ɵpid(131072, i4.AsyncPipe, [i1.ChangeDetectorRef]), i1.ɵpid(131072, i4.AsyncPipe, [i1.ChangeDetectorRef]), i1.ɵpid(131072, i4.AsyncPipe, [i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = ((i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform(_co.chars$)) && i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 3).transform(_co.charOrder$))) && i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 4).transform(_co.charsFound$))); _ck(_v, 1, 0, currVal_0); }, null); }
export function View_CharacterTrackerComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-character-tracker", [], null, null, null, View_CharacterTrackerComponent_0, RenderType_CharacterTrackerComponent)), i1.ɵdid(1, 114688, null, 0, i5.CharacterTrackerComponent, [i6.CharacterService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var CharacterTrackerComponentNgFactory = i1.ɵccf("app-character-tracker", i5.CharacterTrackerComponent, View_CharacterTrackerComponent_Host_0, {}, { select: "select" }, []);
export { CharacterTrackerComponentNgFactory as CharacterTrackerComponentNgFactory };
