/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./key-item.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/material/tooltip";
import * as i3 from "@angular/cdk/overlay";
import * as i4 from "@angular/cdk/scrolling";
import * as i5 from "@angular/cdk/platform";
import * as i6 from "@angular/cdk/a11y";
import * as i7 from "@angular/cdk/bidi";
import * as i8 from "@angular/platform-browser";
import * as i9 from "./key-item.component";
var styles_KeyItemComponent = [i0.styles];
var RenderType_KeyItemComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_KeyItemComponent, data: {} });
export { RenderType_KeyItemComponent as RenderType_KeyItemComponent };
export function View_KeyItemComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "key-item"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 16777216, null, null, 1, "img", [], [[2, "disabled", null], [8, "src", 4]], [[null, "longpress"], [null, "keydown"], [null, "touchend"]], function (_v, en, $event) { var ad = true; if (("longpress" === en)) {
        var pd_0 = (i1.ɵnov(_v, 2).show() !== false);
        ad = (pd_0 && ad);
    } if (("keydown" === en)) {
        var pd_1 = (i1.ɵnov(_v, 2)._handleKeydown($event) !== false);
        ad = (pd_1 && ad);
    } if (("touchend" === en)) {
        var pd_2 = (i1.ɵnov(_v, 2)._handleTouchend() !== false);
        ad = (pd_2 && ad);
    } return ad; }, null, null)), i1.ɵdid(2, 147456, null, 0, i2.MatTooltip, [i3.Overlay, i1.ElementRef, i4.ScrollDispatcher, i1.ViewContainerRef, i1.NgZone, i5.Platform, i6.AriaDescriber, i6.FocusMonitor, i2.MAT_TOOLTIP_SCROLL_STRATEGY, [2, i7.Directionality], [2, i2.MAT_TOOLTIP_DEFAULT_OPTIONS], [2, i8.HAMMER_LOADER]], { message: [0, "message"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.keyItem.name; _ck(_v, 2, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.state === undefined); var currVal_1 = i1.ɵinlineInterpolate(1, "./assets/key-items/", _co.keyItem.id, ".png"); _ck(_v, 1, 0, currVal_0, currVal_1); }); }
export function View_KeyItemComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-key-item", [], null, null, null, View_KeyItemComponent_0, RenderType_KeyItemComponent)), i1.ɵdid(1, 114688, null, 0, i9.KeyItemComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var KeyItemComponentNgFactory = i1.ɵccf("app-key-item", i9.KeyItemComponent, View_KeyItemComponent_Host_0, { keyItem: "keyItem", state: "state" }, {}, []);
export { KeyItemComponentNgFactory as KeyItemComponentNgFactory };
