/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./location-summary.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../poi/poi.component.ngfactory";
import * as i3 from "../poi/poi.component";
import * as i4 from "../location.service";
import * as i5 from "../state.service";
import * as i6 from "@angular/common";
import * as i7 from "@angular/material/tooltip";
import * as i8 from "@angular/cdk/overlay";
import * as i9 from "@angular/cdk/scrolling";
import * as i10 from "@angular/cdk/platform";
import * as i11 from "@angular/cdk/a11y";
import * as i12 from "@angular/cdk/bidi";
import * as i13 from "@angular/platform-browser";
import * as i14 from "./location-summary.component";
var styles_LocationSummaryComponent = [i0.styles];
var RenderType_LocationSummaryComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_LocationSummaryComponent, data: {} });
export { RenderType_LocationSummaryComponent as RenderType_LocationSummaryComponent };
function View_LocationSummaryComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-poi", [], null, null, null, i2.View_PoiComponent_0, i2.RenderType_PoiComponent)), i1.ɵdid(1, 114688, null, 0, i3.PoiComponent, [i4.LocationService, i5.StateService], { locId: [0, "locId"], poiIndex: [1, "poiIndex"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co._locId.data$; var currVal_1 = _v.parent.context.index; _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
function View_LocationSummaryComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 2, null, View_LocationSummaryComponent_3)), i1.ɵdid(2, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), i1.ɵpid(131072, i6.AsyncPipe, [i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 2, 0, i1.ɵnov(_v, 3).transform(_co.state$)).poi[_v.context.index].visible; _ck(_v, 2, 0, currVal_0); }, null); }
function View_LocationSummaryComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "div", [["class", "loc"]], [[2, "selected", null]], null, null, null, null)), (_l()(), i1.ɵeld(1, 16777216, null, null, 4, "img", [["class", "icon"]], [[2, "disabled", null], [8, "src", 4]], [[null, "longpress"], [null, "keydown"], [null, "touchend"]], function (_v, en, $event) { var ad = true; if (("longpress" === en)) {
        var pd_0 = (i1.ɵnov(_v, 2).show() !== false);
        ad = (pd_0 && ad);
    } if (("keydown" === en)) {
        var pd_1 = (i1.ɵnov(_v, 2)._handleKeydown($event) !== false);
        ad = (pd_1 && ad);
    } if (("touchend" === en)) {
        var pd_2 = (i1.ɵnov(_v, 2)._handleTouchend() !== false);
        ad = (pd_2 && ad);
    } return ad; }, null, null)), i1.ɵdid(2, 147456, null, 0, i7.MatTooltip, [i8.Overlay, i1.ElementRef, i9.ScrollDispatcher, i1.ViewContainerRef, i1.NgZone, i10.Platform, i11.AriaDescriber, i11.FocusMonitor, i7.MAT_TOOLTIP_SCROLL_STRATEGY, [2, i12.Directionality], [2, i7.MAT_TOOLTIP_DEFAULT_OPTIONS], [2, i13.HAMMER_LOADER]], { message: [0, "message"] }, null), i1.ɵpid(131072, i6.AsyncPipe, [i1.ChangeDetectorRef]), i1.ɵpid(131072, i6.AsyncPipe, [i1.ChangeDetectorRef]), i1.ɵpid(131072, i6.AsyncPipe, [i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(6, 0, null, null, 3, "div", [["class", "pois"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 2, null, View_LocationSummaryComponent_2)), i1.ɵdid(8, 278528, null, 0, i6.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), i1.ɵpid(131072, i6.AsyncPipe, [i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_3 = i1.ɵinlineInterpolate(1, "", i1.ɵunv(_v, 2, 0, i1.ɵnov(_v, 3).transform(_co.loc$)).name, ""); _ck(_v, 2, 0, currVal_3); var currVal_4 = i1.ɵunv(_v, 8, 0, i1.ɵnov(_v, 9).transform(_co.loc$)).poi; _ck(_v, 8, 0, currVal_4); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.selected; _ck(_v, 0, 0, currVal_0); var currVal_1 = !i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 4).transform(_co.state$)).enabled; var currVal_2 = i1.ɵinlineInterpolate(1, "../assets/locations/", i1.ɵunv(_v, 1, 1, i1.ɵnov(_v, 5).transform(_co.loc$)).id, ".png"); _ck(_v, 1, 0, currVal_1, currVal_2); }); }
export function View_LocationSummaryComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 2, null, View_LocationSummaryComponent_1)), i1.ɵdid(1, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), i1.ɵpid(131072, i6.AsyncPipe, [i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = (i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform(_co.loc$)) != undefined); _ck(_v, 1, 0, currVal_0); }, null); }
export function View_LocationSummaryComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-location-summary", [], null, null, null, View_LocationSummaryComponent_0, RenderType_LocationSummaryComponent)), i1.ɵdid(1, 114688, null, 0, i14.LocationSummaryComponent, [i4.LocationService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var LocationSummaryComponentNgFactory = i1.ɵccf("app-location-summary", i14.LocationSummaryComponent, View_LocationSummaryComponent_Host_0, { locId: "locId", selected: "selected" }, {}, []);
export { LocationSummaryComponentNgFactory as LocationSummaryComponentNgFactory };
