<app-item-adder></app-item-adder>

<table
  *ngIf="items && selectedItems && foundItems"
  cdkDropList
  (cdkDropListDropped)="drop($event)"
>
  <tr
    *ngFor="let id of selectedItems; index as i"
    cdkDrag
  >
    <td *cdkDragPreview>
      <app-item [item]="items[id]"></app-item>
    </td>
    <td class="item-cell">
      <app-item [item]="items[id]"></app-item>
    </td>
    <td class="item-cell">
      <app-shops [itemId]="id"></app-shops>
    </td>
    <td class="item-cell"><button
        mat-icon-button
        (click)="deleteRecord(i)"
      >
        <mat-icon>delete_forever</mat-icon>
      </button></td>
  </tr>
</table>