/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./shop-item-picker.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../node_modules/@angular/material/checkbox/typings/index.ngfactory";
import * as i3 from "@angular/forms";
import * as i4 from "@angular/material/checkbox";
import * as i5 from "@angular/cdk/a11y";
import * as i6 from "@angular/platform-browser/animations";
import * as i7 from "../item/item.component.ngfactory";
import * as i8 from "../item/item.component";
import * as i9 from "@angular/common";
import * as i10 from "./shop-item-picker.component";
import * as i11 from "../item.service";
var styles_ShopItemPickerComponent = [i0.styles];
var RenderType_ShopItemPickerComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ShopItemPickerComponent, data: {} });
export { RenderType_ShopItemPickerComponent as RenderType_ShopItemPickerComponent };
function View_ShopItemPickerComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 4, "mat-checkbox", [["class", "mat-checkbox"]], [[8, "id", 0], [1, "tabindex", 0], [2, "mat-checkbox-indeterminate", null], [2, "mat-checkbox-checked", null], [2, "mat-checkbox-disabled", null], [2, "mat-checkbox-label-before", null], [2, "_mat-animation-noopable", null]], [[null, "change"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("change" === en)) {
        var pd_0 = (_co.checked(_v.context.$implicit, $event.checked) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_MatCheckbox_0, i2.RenderType_MatCheckbox)), i1.ɵprd(5120, null, i3.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i4.MatCheckbox]), i1.ɵdid(3, 8568832, null, 0, i4.MatCheckbox, [i1.ElementRef, i1.ChangeDetectorRef, i5.FocusMonitor, i1.NgZone, [8, null], [2, i4.MAT_CHECKBOX_CLICK_ACTION], [2, i6.ANIMATION_MODULE_TYPE]], { checked: [0, "checked"] }, { change: "change" }), (_l()(), i1.ɵeld(4, 0, null, 0, 1, "app-item", [], null, null, null, i7.View_ItemComponent_0, i7.RenderType_ItemComponent)), i1.ɵdid(5, 114688, null, 0, i8.ItemComponent, [], { item: [0, "item"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_7 = _co.found(_v.context.$implicit); _ck(_v, 3, 0, currVal_7); var currVal_8 = _co.items[_v.context.$implicit]; _ck(_v, 5, 0, currVal_8); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 3).id; var currVal_1 = null; var currVal_2 = i1.ɵnov(_v, 3).indeterminate; var currVal_3 = i1.ɵnov(_v, 3).checked; var currVal_4 = i1.ɵnov(_v, 3).disabled; var currVal_5 = (i1.ɵnov(_v, 3).labelPosition == "before"); var currVal_6 = (i1.ɵnov(_v, 3)._animationMode === "NoopAnimations"); _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); }); }
function View_ShopItemPickerComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ShopItemPickerComponent_2)), i1.ɵdid(2, 278528, null, 0, i9.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.filterItems(_co.items, _co.selectedItems); _ck(_v, 2, 0, currVal_0); }, null); }
export function View_ShopItemPickerComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_ShopItemPickerComponent_1)), i1.ɵdid(1, 16384, null, 0, i9.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.items && _co.selectedItems) && _co.foundItems); _ck(_v, 1, 0, currVal_0); }, null); }
export function View_ShopItemPickerComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-shop-item-picker", [], null, null, null, View_ShopItemPickerComponent_0, RenderType_ShopItemPickerComponent)), i1.ɵdid(1, 114688, null, 0, i10.ShopItemPickerComponent, [i11.ItemService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ShopItemPickerComponentNgFactory = i1.ɵccf("app-shop-item-picker", i10.ShopItemPickerComponent, View_ShopItemPickerComponent_Host_0, { location: "location", shopType: "shopType" }, {}, []);
export { ShopItemPickerComponentNgFactory as ShopItemPickerComponentNgFactory };
