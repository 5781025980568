/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./boss.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/material/tooltip";
import * as i3 from "@angular/cdk/overlay";
import * as i4 from "@angular/cdk/scrolling";
import * as i5 from "@angular/cdk/platform";
import * as i6 from "@angular/cdk/a11y";
import * as i7 from "@angular/cdk/bidi";
import * as i8 from "@angular/platform-browser";
import * as i9 from "./boss.component";
var styles_BossComponent = [i0.styles];
var RenderType_BossComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_BossComponent, data: {} });
export { RenderType_BossComponent as RenderType_BossComponent };
export function View_BossComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "boss"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 16777216, null, null, 1, "img", [["class", "icon"]], [[2, "disabled", null], [8, "src", 4]], [[null, "longpress"], [null, "keydown"], [null, "touchend"]], function (_v, en, $event) { var ad = true; if (("longpress" === en)) {
        var pd_0 = (i1.ɵnov(_v, 2).show() !== false);
        ad = (pd_0 && ad);
    } if (("keydown" === en)) {
        var pd_1 = (i1.ɵnov(_v, 2)._handleKeydown($event) !== false);
        ad = (pd_1 && ad);
    } if (("touchend" === en)) {
        var pd_2 = (i1.ɵnov(_v, 2)._handleTouchend() !== false);
        ad = (pd_2 && ad);
    } return ad; }, null, null)), i1.ɵdid(2, 147456, null, 0, i2.MatTooltip, [i3.Overlay, i1.ElementRef, i4.ScrollDispatcher, i1.ViewContainerRef, i1.NgZone, i5.Platform, i6.AriaDescriber, i6.FocusMonitor, i2.MAT_TOOLTIP_SCROLL_STRATEGY, [2, i7.Directionality], [2, i2.MAT_TOOLTIP_DEFAULT_OPTIONS], [2, i8.HAMMER_LOADER]], { message: [0, "message"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.boss.name; _ck(_v, 2, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.state === undefined); var currVal_1 = i1.ɵinlineInterpolate(1, "./assets/bosses/", _co.boss.id, ".png"); _ck(_v, 1, 0, currVal_0, currVal_1); }); }
export function View_BossComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-boss", [], null, null, null, View_BossComponent_0, RenderType_BossComponent)), i1.ɵdid(1, 114688, null, 0, i9.BossComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var BossComponentNgFactory = i1.ɵccf("app-boss", i9.BossComponent, View_BossComponent_Host_0, { boss: "boss", state: "state" }, {}, []);
export { BossComponentNgFactory as BossComponentNgFactory };
