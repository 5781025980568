import { StorageService } from "angular-webstorage-service";
import { BehaviorSubject } from "rxjs";
import { CONFIG_VERSION } from "./config.model";
import * as i0 from "@angular/core";
import * as i1 from "angular-webstorage-service";
export { Config, Flags } from "./config.model";
var STORAGE_KEY = "workshop.config";
var ConfigService = /** @class */ (function () {
    function ConfigService(storage) {
        this.storage = storage;
        this.configData = this.storage.get(STORAGE_KEY) || this.defaultConfig();
        this.updateConfig();
        this._config = (new BehaviorSubject(this.configData));
        this.config$ = this._config.asObservable();
        this.store();
    }
    ConfigService.prototype.updateConfig = function () {
        var defaultConfig = this.defaultConfig();
        if (this.configData.version === 1) {
            this.configData.options = defaultConfig.options;
        }
        else if (this.configData.version === 2) {
            this.configData.flags.Kq = defaultConfig.flags.Kq;
            this.configData.flags.Km = defaultConfig.flags.Km;
            this.configData.flags.Kt = defaultConfig.flags.Kt;
        }
        if (!this.configData.flags) {
            this.configData.flags = defaultConfig.flags;
        }
        if (!this.configData.flags.nodupes) {
            this.configData.flags.nodupes = defaultConfig.flags.nodupes;
        }
        this.configData.version = CONFIG_VERSION;
    };
    ConfigService.prototype.getConfig = function () {
        return this.config$;
    };
    ConfigService.prototype.store = function () {
        this._config.next(this.configData);
        this.storage.set(STORAGE_KEY, this.configData);
    };
    ConfigService.prototype.updateSelectedItems = function (selectedItems) {
        this.configData.selected_items = selectedItems;
        this.store();
    };
    ConfigService.prototype.updateFlags = function (flags) {
        this.configData.flags = flags;
        this.store();
    };
    ConfigService.prototype.defaultConfig = function () {
        return {
            version: CONFIG_VERSION,
            selected_items: [],
            flags: {
                Nc: false,
                Nk: false,
                Kq: false,
                Km: false,
                Kt: false,
                nodupes: false
            },
            options: {
                always_remove_key: true
            }
        };
    };
    ConfigService.ngInjectableDef = i0.defineInjectable({ factory: function ConfigService_Factory() { return new ConfigService(i0.inject(i1.SESSION_STORAGE)); }, token: ConfigService, providedIn: "root" });
    return ConfigService;
}());
export { ConfigService };
