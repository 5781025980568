<div
  class="loc"
  [class.selected]="selected"
  *ngIf="(loc$ | async) != undefined"
>
  <img
    class="icon"
    [class.disabled]="!(state$ | async).enabled"
    src="../assets/locations/{{(loc$ | async).id}}.png"
    matTooltip="{{(loc$ | async).name}}"
  />
  <div class="pois">
    <div *ngFor="let poi of (loc$ | async).poi; index as i">
      <app-poi
        *ngIf="(state$ | async).poi[i].visible"
        [locId]="_locId.data$"
        [poiIndex]="i"
      ></app-poi>
    </div>
  </div>
</div>