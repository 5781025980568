/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../node_modules/@angular/material/checkbox/typings/index.ngfactory";
import * as i2 from "@angular/material/checkbox";
import * as i3 from "@angular/cdk/a11y";
import * as i4 from "@angular/platform-browser/animations";
import * as i5 from "@angular/forms";
import * as i6 from "@angular/flex-layout/flex";
import * as i7 from "@angular/flex-layout/core";
import * as i8 from "@angular/common";
import * as i9 from "@angular/material/dialog";
import * as i10 from "ngx-if-empty-or-has-items";
import * as i11 from "../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i12 from "@angular/material/button";
import * as i13 from "@angular/cdk/platform";
import * as i14 from "./shops.component";
import * as i15 from "../location.service";
import * as i16 from "./shops.component.scss.shim.ngstyle";
import * as i17 from "../item.service";
var styles_ShopsDialogComponent = [];
var RenderType_ShopsDialogComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_ShopsDialogComponent, data: {} });
export { RenderType_ShopsDialogComponent as RenderType_ShopsDialogComponent };
function View_ShopsDialogComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 6, "mat-checkbox", [["class", "mat-checkbox"]], [[8, "id", 0], [1, "tabindex", 0], [2, "mat-checkbox-indeterminate", null], [2, "mat-checkbox-checked", null], [2, "mat-checkbox-disabled", null], [2, "mat-checkbox-label-before", null], [2, "_mat-animation-noopable", null], [2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "ngModelChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("ngModelChange" === en)) {
        var pd_0 = ((_co.selected[_v.context.$implicit] = $event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i1.View_MatCheckbox_0, i1.RenderType_MatCheckbox)), i0.ɵdid(1, 8568832, null, 0, i2.MatCheckbox, [i0.ElementRef, i0.ChangeDetectorRef, i3.FocusMonitor, i0.NgZone, [8, null], [2, i2.MAT_CHECKBOX_CLICK_ACTION], [2, i4.ANIMATION_MODULE_TYPE]], null, null), i0.ɵprd(1024, null, i5.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i2.MatCheckbox]), i0.ɵdid(3, 671744, null, 0, i5.NgModel, [[8, null], [8, null], [8, null], [6, i5.NG_VALUE_ACCESSOR]], { model: [0, "model"] }, { update: "ngModelChange" }), i0.ɵprd(2048, null, i5.NgControl, null, [i5.NgModel]), i0.ɵdid(5, 16384, null, 0, i5.NgControlStatus, [[4, i5.NgControl]], null, null), (_l()(), i0.ɵted(6, 0, ["", ""]))], function (_ck, _v) { var _co = _v.component; var currVal_14 = _co.selected[_v.context.$implicit]; _ck(_v, 3, 0, currVal_14); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵnov(_v, 1).id; var currVal_1 = null; var currVal_2 = i0.ɵnov(_v, 1).indeterminate; var currVal_3 = i0.ɵnov(_v, 1).checked; var currVal_4 = i0.ɵnov(_v, 1).disabled; var currVal_5 = (i0.ɵnov(_v, 1).labelPosition == "before"); var currVal_6 = (i0.ɵnov(_v, 1)._animationMode === "NoopAnimations"); var currVal_7 = i0.ɵnov(_v, 5).ngClassUntouched; var currVal_8 = i0.ɵnov(_v, 5).ngClassTouched; var currVal_9 = i0.ɵnov(_v, 5).ngClassPristine; var currVal_10 = i0.ɵnov(_v, 5).ngClassDirty; var currVal_11 = i0.ɵnov(_v, 5).ngClassValid; var currVal_12 = i0.ɵnov(_v, 5).ngClassInvalid; var currVal_13 = i0.ɵnov(_v, 5).ngClassPending; _ck(_v, 0, 1, [currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8, currVal_9, currVal_10, currVal_11, currVal_12, currVal_13]); var currVal_15 = _co.locs[_v.context.$implicit].name; _ck(_v, 6, 0, currVal_15); }); }
function View_ShopsDialogComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "div", [["fxLayout", "column"]], null, null, null, null, null)), i0.ɵdid(1, 737280, null, 0, i6.LayoutDirective, [i7.MediaMonitor, i0.ElementRef, i7.StyleUtils], { layout: [0, "layout"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ShopsDialogComponent_2)), i0.ɵdid(3, 278528, null, 0, i8.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "column"; _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.locOrder; _ck(_v, 3, 0, currVal_1); }, null); }
export function View_ShopsDialogComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "h1", [["class", "mat-dialog-title"], ["mat-dialog-title", ""]], [[8, "id", 0]], null, null, null, null)), i0.ɵdid(1, 81920, null, 0, i9.MatDialogTitle, [[2, i9.MatDialogRef], i0.ElementRef, i9.MatDialog], null, null), (_l()(), i0.ɵted(-1, null, ["Shops"])), (_l()(), i0.ɵeld(3, 0, null, null, 3, "div", [["class", "mat-dialog-content"], ["mat-dialog-content", ""]], null, null, null, null, null)), i0.ɵdid(4, 16384, null, 0, i9.MatDialogContent, [], null, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ShopsDialogComponent_1)), i0.ɵdid(6, 16384, null, 0, i10.NgxIfNotEmptyDirective, [i0.ViewContainerRef, i0.TemplateRef], { ngxIfNotEmpty: [0, "ngxIfNotEmpty"] }, null), (_l()(), i0.ɵeld(7, 0, null, null, 8, "div", [["class", "mat-dialog-actions"], ["mat-dialog-actions", ""]], null, null, null, null, null)), i0.ɵdid(8, 16384, null, 0, i9.MatDialogActions, [], null, null), (_l()(), i0.ɵeld(9, 0, null, null, 2, "button", [["mat-button", ""]], [[8, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onNoClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i11.View_MatButton_0, i11.RenderType_MatButton)), i0.ɵdid(10, 180224, null, 0, i12.MatButton, [i0.ElementRef, i13.Platform, i3.FocusMonitor, [2, i4.ANIMATION_MODULE_TYPE]], null, null), (_l()(), i0.ɵted(-1, 0, ["No Thanks"])), (_l()(), i0.ɵeld(12, 0, null, null, 3, "button", [["cdkFocusInitial", ""], ["mat-button", ""], ["type", "button"]], [[8, "disabled", 0], [2, "_mat-animation-noopable", null], [1, "aria-label", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 14).dialogRef.close(i0.ɵnov(_v, 14).dialogResult) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i11.View_MatButton_0, i11.RenderType_MatButton)), i0.ɵdid(13, 180224, null, 0, i12.MatButton, [i0.ElementRef, i13.Platform, i3.FocusMonitor, [2, i4.ANIMATION_MODULE_TYPE]], null, null), i0.ɵdid(14, 606208, null, 0, i9.MatDialogClose, [[2, i9.MatDialogRef], i0.ElementRef, i9.MatDialog], { dialogResult: [0, "dialogResult"] }, null), (_l()(), i0.ɵted(-1, 0, ["Ok"]))], function (_ck, _v) { var _co = _v.component; _ck(_v, 1, 0); var currVal_1 = _co.locs; _ck(_v, 6, 0, currVal_1); var currVal_7 = _co.selectedIds(); _ck(_v, 14, 0, currVal_7); }, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 1).id; _ck(_v, 0, 0, currVal_0); var currVal_2 = (i0.ɵnov(_v, 10).disabled || null); var currVal_3 = (i0.ɵnov(_v, 10)._animationMode === "NoopAnimations"); _ck(_v, 9, 0, currVal_2, currVal_3); var currVal_4 = (i0.ɵnov(_v, 13).disabled || null); var currVal_5 = (i0.ɵnov(_v, 13)._animationMode === "NoopAnimations"); var currVal_6 = i0.ɵnov(_v, 14).ariaLabel; _ck(_v, 12, 0, currVal_4, currVal_5, currVal_6); }); }
export function View_ShopsDialogComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-shops-dialog", [], null, null, null, View_ShopsDialogComponent_0, RenderType_ShopsDialogComponent)), i0.ɵdid(1, 49152, null, 0, i14.ShopsDialogComponent, [i9.MatDialogRef, i9.MAT_DIALOG_DATA, i15.LocationService], null, null)], null, null); }
var ShopsDialogComponentNgFactory = i0.ɵccf("app-shops-dialog", i14.ShopsDialogComponent, View_ShopsDialogComponent_Host_0, {}, {}, []);
export { ShopsDialogComponentNgFactory as ShopsDialogComponentNgFactory };
var styles_ShopsComponent = [i16.styles];
var RenderType_ShopsComponent = i0.ɵcrt({ encapsulation: 0, styles: styles_ShopsComponent, data: {} });
export { RenderType_ShopsComponent as RenderType_ShopsComponent };
function View_ShopsComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "div", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.edit($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(-1, null, [" shop "]))], null, null); }
function View_ShopsComponent_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i0.ɵted(1, null, ["", "", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.locs[_v.context.$implicit.key].name; var currVal_1 = (_v.context.last ? "" : ", "); _ck(_v, 1, 0, currVal_0, currVal_1); }); }
function View_ShopsComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "div", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.edit($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵand(16777216, null, null, 2, null, View_ShopsComponent_4)), i0.ɵdid(2, 278528, null, 0, i8.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), i0.ɵpid(0, i8.KeyValuePipe, [i0.KeyValueDiffers])], function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵunv(_v, 2, 0, i0.ɵnov(_v, 3).transform(_co.found)); _ck(_v, 2, 0, currVal_0); }, null); }
function View_ShopsComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, "div", [], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ShopsComponent_2)), i0.ɵdid(2, 16384, null, 0, i10.NgxIfEmptyDirective, [i0.ViewContainerRef, i0.TemplateRef], { ngxIfEmpty: [0, "ngxIfEmpty"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ShopsComponent_3)), i0.ɵdid(4, 16384, null, 0, i10.NgxIfNotEmptyDirective, [i0.ViewContainerRef, i0.TemplateRef], { ngxIfNotEmpty: [0, "ngxIfNotEmpty"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.found; _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.found; _ck(_v, 4, 0, currVal_1); }, null); }
export function View_ShopsComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵand(16777216, null, null, 1, null, View_ShopsComponent_1)), i0.ɵdid(1, 16384, null, 0, i8.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.locs && _co.found); _ck(_v, 1, 0, currVal_0); }, null); }
export function View_ShopsComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-shops", [], null, null, null, View_ShopsComponent_0, RenderType_ShopsComponent)), i0.ɵdid(1, 114688, null, 0, i14.ShopsComponent, [i9.MatDialog, i15.LocationService, i17.ItemService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ShopsComponentNgFactory = i0.ɵccf("app-shops", i14.ShopsComponent, View_ShopsComponent_Host_0, { itemId: "itemId" }, {}, []);
export { ShopsComponentNgFactory as ShopsComponentNgFactory };
