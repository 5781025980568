import { HttpClient } from "@angular/common/http";
import { BehaviorSubject } from "rxjs";
import { ObservableData } from "./observable-data";
import { StateService } from "./state.service";
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "./state.service";
var KeyItemService = /** @class */ (function () {
    function KeyItemService(http, stateService) {
        var _this = this;
        this.http = http;
        this.stateService = stateService;
        this._keyItems = new BehaviorSubject(undefined);
        this.keyItems$ = this._keyItems.asObservable();
        this._keyItemOrder = (new BehaviorSubject(undefined));
        this.keyItemOrder$ = this._keyItemOrder.asObservable();
        this.state = new ObservableData(undefined);
        this.stateService.getState().subscribe(function (state) {
            var newState = Object.assign({}, state.key_items);
            if ("hook" in state.key_items || "magma-key" in state.key_items) {
                newState["underground"] = { location: "virt", slot: 0 };
            }
            else {
                delete newState["underground"];
            }
            if (232 in state.found_items &&
                Object.keys(state.found_items[232]).length > 0 &&
                !("pass" in state.key_items)) {
                newState["pass"] = { location: "virt", slot: 0 };
            }
            _this.state.data = newState;
            _this.state.next();
        });
        this.http
            .get("./assets/data/key-items.json")
            .subscribe(function (keyItems) {
            _this._keyItemOrder.next(keyItems.map(function (ki) { return ki.id; }));
            _this._keyItems.next(keyItems.reduce(function (kis, ki) {
                kis[ki.id] = ki;
                return kis;
            }, {}));
        });
    }
    KeyItemService.prototype.getKeyItems = function () {
        return this.keyItems$;
    };
    KeyItemService.prototype.getKeyItemOrder = function () {
        return this.keyItemOrder$;
    };
    KeyItemService.prototype.getKeyItemsFound = function () {
        return this.state.data$;
    };
    KeyItemService.ngInjectableDef = i0.defineInjectable({ factory: function KeyItemService_Factory() { return new KeyItemService(i0.inject(i1.HttpClient), i0.inject(i2.StateService)); }, token: KeyItemService, providedIn: "root" });
    return KeyItemService;
}());
export { KeyItemService };
