<form class="form">
  <mat-form-field class="full-width">
    <input
      type="text"
      placeholder="Add item"
      aria-label="Number"
      matInput
      [formControl]="searchControl"
      [matAutocomplete]="auto"
    >
    <mat-autocomplete
      #auto="matAutocomplete"
      autoActiveFirstOption="true"
      [displayWith]="displayItem"
      (optionSelected)="addItem($event.option.value)"
    >
      <mat-option
        *ngFor="let item of filteredItems | async"
        [value]="item"
      >
        <app-item [item]="item"></app-item>
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>
</form>