import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { ConfigService } from "./config.service";
import { StateService } from "./state.service";
import * as i0 from "@angular/core";
import * as i1 from "./config.service";
import * as i2 from "@angular/common/http";
import * as i3 from "./state.service";
var STORAGE_KEY = "tracker.items.service";
var ItemService = /** @class */ (function () {
    function ItemService(configService, http, stateService) {
        var _this = this;
        this.configService = configService;
        this.http = http;
        this.stateService = stateService;
        this.items = this.http.get("./assets/data/items.json");
        this.configService.getConfig().subscribe(function (c) {
            _this.selectedItems = c.selected_items;
        });
        this.stateService.getState().subscribe(function (s) {
            _this.foundItems = s.found_items;
        });
    }
    ItemService.prototype.getItems = function () {
        return this.items;
    };
    ItemService.prototype.getSelectedItems = function () {
        return this.configService.getConfig().pipe(map(function (c) { return c.selected_items; }));
    };
    ItemService.prototype.getFoundItems = function () {
        return this.stateService.getState().pipe(map(function (s) { return s.found_items; }));
    };
    ItemService.prototype.addSelectedItem = function (item) {
        console.log("add", item);
        this.selectedItems.push(item.id);
        this.configService.updateSelectedItems(this.selectedItems);
    };
    ItemService.prototype.deleteSelectedItem = function (index) {
        this.selectedItems.splice(index, 1);
        this.configService.updateSelectedItems(this.selectedItems);
    };
    ItemService.prototype.moveItemRecord = function (prev, next) {
        var id = this.selectedItems[prev];
        this.selectedItems.splice(prev, 1);
        this.selectedItems.splice(next, 0, id);
        this.configService.updateSelectedItems(this.selectedItems);
    };
    ItemService.prototype.updateFoundItems = function (itemId, found) {
        this.foundItems[itemId] = found;
        console.log(this.foundItems);
        this.stateService.updateFoundItems(this.foundItems);
    };
    ItemService.prototype.markFoundItem = function (itemId, location, found) {
        if (!(itemId in this.foundItems)) {
            this.foundItems[itemId] = {};
        }
        if (found) {
            this.foundItems[itemId][location] = true;
        }
        else {
            delete this.foundItems[itemId][location];
        }
        this.stateService.updateFoundItems(this.foundItems);
    };
    ItemService.ngInjectableDef = i0.defineInjectable({ factory: function ItemService_Factory() { return new ItemService(i0.inject(i1.ConfigService), i0.inject(i2.HttpClient), i0.inject(i3.StateService)); }, token: ItemService, providedIn: "root" });
    return ItemService;
}());
export { ItemService };
