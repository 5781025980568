import { HttpClient } from "@angular/common/http";
import { BehaviorSubject, combineLatest } from "rxjs";
import { map } from "rxjs/operators";
import { Found, FoundLocation, State, StateService } from "./state.service";
import { ConfigService } from "./config.service";
import { KeyItemService } from "./key-item.service";
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "./config.service";
import * as i3 from "./state.service";
import * as i4 from "./key-item.service";
var LocationService = /** @class */ (function () {
    function LocationService(http, configService, stateService, keyItemService) {
        var _this = this;
        this.http = http;
        this.configService = configService;
        this.stateService = stateService;
        this.keyItemService = keyItemService;
        this._locations = (new BehaviorSubject(undefined));
        this.locations = this._locations.asObservable();
        this._locationOrder = (new BehaviorSubject(undefined));
        this.locationOrder = this._locationOrder.asObservable();
        this.stateService.getState().subscribe(function (state) {
            _this.state = state;
        });
        this.configService.getConfig().subscribe(function (config) {
            _this.config = config;
        });
        this.locationState$ = combineLatest(this.stateService.getState(), this.configService.getConfig(), this.keyItemService.getKeyItemsFound(), this.locations).pipe(map(function (results) {
            var state = Object.assign({}, results[0]);
            state.key_items = results[2];
            return _this.processState(state, results[1], results[3]);
        }));
        this.locationState$.subscribe(function (s) {
            _this.locationStateData = s;
        });
        this.http.get("./assets/data/locations.json").subscribe(function (l) {
            _this.locationOrderData = l.map(function (a) { return a.id; });
            _this._locationOrder.next(_this.locationOrderData);
            _this.locationsData = l.reduce(function (locs, o) {
                locs[o.id] = o;
                return locs;
            }, {});
            _this._locations.next(_this.locationsData);
        });
    }
    LocationService.prototype.checkReq = function (state, poi) {
        if ("reqs" in poi) {
            for (var _i = 0, _a = poi.reqs; _i < _a.length; _i++) {
                var req = _a[_i];
                if (!(req in state.key_items) && !(req in state.bosses)) {
                    return false;
                }
            }
        }
        return true;
    };
    LocationService.prototype.processFlags = function (config, poi) {
        if (!("flags" in poi)) {
            return true;
        }
        var visible = false;
        for (var _i = 0, _a = poi.flags; _i < _a.length; _i++) {
            var flag = _a[_i];
            var invert = false;
            if (flag.substring(0, 1) === "!") {
                invert = true;
                flag = flag.substring(1);
            }
            if (flag in config.flags) {
                var flagVal = config.flags[flag];
                if (invert) {
                    flagVal = !flagVal;
                }
                visible = visible || flagVal;
            }
            else {
                console.log("Flag " + flag + " unknown");
            }
        }
        return visible;
    };
    LocationService.prototype.processPois = function (state, config, loc, l) {
        if (!("poi" in loc)) {
            l.enabled = true;
        }
        else {
            for (var _i = 0, _a = Object.keys(loc.poi); _i < _a.length; _i++) {
                var p = _a[_i];
                var poi = loc.poi[p];
                var ps = {
                    enabled: false,
                    visible: true,
                    hasKeyItem: false,
                    foundItem: false
                };
                if (this.checkReq(state, poi)) {
                    ps.enabled = true;
                    // If any of the pois are enabled,  The location stays enabled.
                    l.enabled = true;
                }
                // Evaluate flags for poi visibility.
                ps.visible = this.processFlags(config, poi);
                if (state.location_info[loc.id]) {
                    var info = state.location_info[loc.id];
                    if (info.poi_states[p]) {
                        ps.character = info.poi_states[p].char;
                    }
                    if (info.poi_found_item[p]) {
                        ps.foundItem = true;
                    }
                }
                // Check if this poi has a key item associated with it (i.e. bosses
                // in Kq and Km.
                if ("key_item_flag" in poi) {
                    var ki_flag = poi.key_item_flag;
                    if (ki_flag in config.flags && config.flags[ki_flag]) {
                        ps.hasKeyItem = true;
                    }
                }
                l.poi[p] = ps;
            }
        }
    };
    LocationService.prototype.processFound = function (state, states, type, stateKey, poiKey) {
        for (var _i = 0, _a = Object.keys(state[stateKey]); _i < _a.length; _i++) {
            var id = _a[_i];
            var k = state[stateKey][id];
            if (k.location === "virt") {
                continue;
            }
            if (k.type && k.type === type) {
                states[k.location].poi[k.slot][poiKey] = id;
            }
        }
    };
    LocationService.prototype.processState = function (state, config, locationsData) {
        var states = {};
        if (state === undefined ||
            config === undefined ||
            locationsData === undefined) {
            return states;
        }
        for (var _i = 0, _a = Object.keys(locationsData); _i < _a.length; _i++) {
            var locId = _a[_i];
            var loc = locationsData[locId];
            var l = { enabled: false, poi: [], trapped_chests: {} };
            this.processPois(state, config, loc, l);
            if (locId in state.trapped_chests) {
                var chests = state.trapped_chests[locId];
                for (var _b = 0, _c = Object.keys(chests); _b < _c.length; _b++) {
                    var c = _c[_b];
                    l.trapped_chests[c] = {
                        found: chests[c],
                        keyItem: null,
                        foundItem: false
                    };
                }
                for (var _d = 0, _e = Object.keys(state.location_info[locId].chest_found_item); _d < _e.length; _d++) {
                    var c = _e[_d];
                    if (state.location_info[locId].chest_found_item[c]) {
                        l.trapped_chests[c].foundItem = true;
                    }
                }
            }
            states[locId] = l;
        }
        this.processFound(state, states, "poi", "key_items", "keyItem");
        this.processFound(state, states, "poi", "bosses", "boss");
        this.processFound(state, states, "boss", "key_items", "bossKeyItem");
        for (var _f = 0, _g = Object.keys(state.key_items); _f < _g.length; _f++) {
            var id = _g[_f];
            var k = state.key_items[id];
            if (k.location === "virt" || k.type !== "trapped") {
                continue;
            }
            if (!states[k.location].trapped_chests[k.slot]) {
                states[k.location].trapped_chests[k.slot] = {
                    found: false,
                    keyItem: null,
                    foundItem: false
                };
            }
            states[k.location].trapped_chests[k.slot].keyItem = id;
        }
        return states;
    };
    LocationService.prototype.getLocations = function () {
        return this.locations;
    };
    LocationService.prototype.getLocationOrder = function () {
        return this.locationOrder;
    };
    LocationService.prototype.getLocation = function (id$) {
        return combineLatest(this.locations, id$).pipe(map(function (r) {
            var locs = r[0];
            var id = r[1];
            return locs[id];
        }));
    };
    LocationService.prototype.getLocationState = function (location$) {
        return combineLatest(this.locationState$, location$).pipe(map(function (r) {
            var states = r[0];
            var loc = r[1];
            var l = {
                enabled: false,
                poi: [],
                trapped_chests: {}
            };
            if (states === undefined || loc === undefined) {
                return l;
            }
            return states[loc.id];
        }));
    };
    LocationService.prototype.processPoi = function (poiType, poiKey, found, l, poiId, addFunc, deleteFunc) {
        if (l === undefined) {
            return;
        }
        var loc = this.locationsData[l];
        if (this.config.options.always_remove_key) {
            if (poiId in found) {
                deleteFunc();
                return;
            }
        }
        else {
            // First check if poi is already recorded.  If so, remove it.
            for (var _i = 0, _a = Object.keys(loc.poi); _i < _a.length; _i++) {
                var p = _a[_i];
                var poi = loc.poi[p];
                var poiState = this.locationStateData[l].poi[p];
                if (poi.type === poiType && poiState[poiKey] === poiId) {
                    deleteFunc();
                    return;
                }
            }
        }
        // Not here, lets see if we have a free slot.
        for (var _b = 0, _c = Object.keys(loc.poi); _b < _c.length; _b++) {
            var p = _c[_b];
            var poi = loc.poi[p];
            var poiState = this.locationStateData[l].poi[p];
            if (poi.type === poiType && !(poiKey in poiState)) {
                // addFunc() will make sure the key item is not recorded somewhere else.
                addFunc(Number(p));
                return;
            }
        }
    };
    LocationService.prototype.processKeyItem = function (loc, keyItem) {
        var _this = this;
        this.processPoi("key", "keyItem", this.state.key_items, loc, keyItem, function (slot) {
            _this.stateService.recordKeyItem(keyItem, "poi", loc, slot);
        }, function () {
            _this.stateService.unrecordKeyItem(keyItem);
        });
    };
    LocationService.prototype.processChar = function (locId, charId) {
        if (!locId || !charId) {
            return;
        }
        var loc = this.locationsData[locId];
        var locState = this.locationStateData[locId];
        for (var _i = 0, _a = Object.keys(locState.poi); _i < _a.length; _i++) {
            var i = _a[_i];
            var poiState = locState.poi[i];
            if (poiState.character === charId) {
                this.stateService.unrecordCharacter(charId, locId, Number(i));
                return;
            }
        }
        for (var _b = 0, _c = Object.keys(loc.poi); _b < _c.length; _b++) {
            var i = _c[_b];
            var poi = loc.poi[i];
            var poiState = locState.poi[i];
            if (poi.type === "char" && !poiState.character) {
                this.stateService.recordCharacter(charId, locId, Number(i), !this.config.flags.nodupes);
                return;
            }
        }
    };
    LocationService.prototype.processBoss = function (loc, boss) {
        var _this = this;
        this.processPoi("boss", "boss", this.state.bosses, loc, boss, function (slot) {
            _this.stateService.recordBoss(boss, "poi", loc, slot);
        }, function () {
            _this.stateService.unrecordBoss(boss);
        });
    };
    LocationService.prototype.processFoundKeyItem = function (type, loc, slot, keyItem) {
        var found = this.state.key_items[keyItem];
        if (found === undefined) {
            this.stateService.recordKeyItem(keyItem, type, loc, slot);
        }
        else if (found.type === type &&
            found.location === loc &&
            found.slot === slot) {
            this.stateService.unrecordKeyItem(keyItem);
        }
    };
    LocationService.prototype.processBossKeyItem = function (loc, slot, keyItem) {
        this.processFoundKeyItem("boss", loc, slot, keyItem);
    };
    LocationService.prototype.processTrappedKeyItem = function (loc, slot, keyItem) {
        this.processFoundKeyItem("trapped", loc, slot, keyItem);
    };
    LocationService.prototype.processChestKeyItem = function (loc, slot, keyItem) {
        var found = this.state.key_items[keyItem];
        if (found === undefined) {
            this.stateService.recordKeyItem(keyItem, "trapped", loc, slot);
        }
        else if (found.type === "trapped" &&
            found.location === loc &&
            found.slot === slot) {
            this.stateService.unrecordKeyItem(keyItem);
        }
    };
    LocationService.prototype.recordTrappedChest = function (locId, chest, found) {
        this.stateService.recordTrappedChest(locId, chest, found);
    };
    LocationService.ngInjectableDef = i0.defineInjectable({ factory: function LocationService_Factory() { return new LocationService(i0.inject(i1.HttpClient), i0.inject(i2.ConfigService), i0.inject(i3.StateService), i0.inject(i4.KeyItemService)); }, token: LocationService, providedIn: "root" });
    return LocationService;
}());
export { LocationService };
