/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./flags.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../flag/flag.component.ngfactory";
import * as i3 from "../flag/flag.component";
import * as i4 from "./flags.component";
import * as i5 from "../config.service";
var styles_FlagsComponent = [i0.styles];
var RenderType_FlagsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_FlagsComponent, data: {} });
export { RenderType_FlagsComponent as RenderType_FlagsComponent };
export function View_FlagsComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Only a subset of flags are supported"])), (_l()(), i1.ɵeld(2, 0, null, null, 12, "div", [["class", "flags"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "app-flag", [["desc", "No free characters."], ["flag", "Nc"]], null, [[null, "change"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("change" === en)) {
        var pd_0 = (_co.change("Nc", $event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_FlagComponent_0, i2.RenderType_FlagComponent)), i1.ɵdid(4, 114688, null, 0, i3.FlagComponent, [], { enabled: [0, "enabled"], flag: [1, "flag"], desc: [2, "desc"] }, { change: "change" }), (_l()(), i1.ɵeld(5, 0, null, null, 1, "app-flag", [["desc", "No free key items."], ["flag", "Nk"]], null, [[null, "change"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("change" === en)) {
        var pd_0 = (_co.change("Nk", $event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_FlagComponent_0, i2.RenderType_FlagComponent)), i1.ɵdid(6, 114688, null, 0, i3.FlagComponent, [], { enabled: [0, "enabled"], flag: [1, "flag"], desc: [2, "desc"] }, { change: "change" }), (_l()(), i1.ɵeld(7, 0, null, null, 1, "app-flag", [["desc", "Mix key-items with summon quest rewards."], ["flag", "Kq"]], null, [[null, "change"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("change" === en)) {
        var pd_0 = (_co.change("Kq", $event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_FlagComponent_0, i2.RenderType_FlagComponent)), i1.ɵdid(8, 114688, null, 0, i3.FlagComponent, [], { enabled: [0, "enabled"], flag: [1, "flag"], desc: [2, "desc"] }, { change: "change" }), (_l()(), i1.ɵeld(9, 0, null, null, 1, "app-flag", [["desc", "Mix key-items with moon boss rewards."], ["flag", "Km"]], null, [[null, "change"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("change" === en)) {
        var pd_0 = (_co.change("Km", $event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_FlagComponent_0, i2.RenderType_FlagComponent)), i1.ɵdid(10, 114688, null, 0, i3.FlagComponent, [], { enabled: [0, "enabled"], flag: [1, "flag"], desc: [2, "desc"] }, { change: "change" }), (_l()(), i1.ɵeld(11, 0, null, null, 1, "app-flag", [["desc", "Mix key-items with trapped chests."], ["flag", "Kt"]], null, [[null, "change"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("change" === en)) {
        var pd_0 = (_co.change("Kt", $event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_FlagComponent_0, i2.RenderType_FlagComponent)), i1.ɵdid(12, 114688, null, 0, i3.FlagComponent, [], { enabled: [0, "enabled"], flag: [1, "flag"], desc: [2, "desc"] }, { change: "change" }), (_l()(), i1.ɵeld(13, 0, null, null, 1, "app-flag", [["desc", "Disallow duplicate characters."], ["flag", "nodupes"]], null, [[null, "change"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("change" === en)) {
        var pd_0 = (_co.change("nodupes", $event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_FlagComponent_0, i2.RenderType_FlagComponent)), i1.ɵdid(14, 114688, null, 0, i3.FlagComponent, [], { enabled: [0, "enabled"], flag: [1, "flag"], desc: [2, "desc"] }, { change: "change" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.flags.Nc; var currVal_1 = "Nc"; var currVal_2 = "No free characters."; _ck(_v, 4, 0, currVal_0, currVal_1, currVal_2); var currVal_3 = _co.flags.Nk; var currVal_4 = "Nk"; var currVal_5 = "No free key items."; _ck(_v, 6, 0, currVal_3, currVal_4, currVal_5); var currVal_6 = _co.flags.Kq; var currVal_7 = "Kq"; var currVal_8 = "Mix key-items with summon quest rewards."; _ck(_v, 8, 0, currVal_6, currVal_7, currVal_8); var currVal_9 = _co.flags.Km; var currVal_10 = "Km"; var currVal_11 = "Mix key-items with moon boss rewards."; _ck(_v, 10, 0, currVal_9, currVal_10, currVal_11); var currVal_12 = _co.flags.Kt; var currVal_13 = "Kt"; var currVal_14 = "Mix key-items with trapped chests."; _ck(_v, 12, 0, currVal_12, currVal_13, currVal_14); var currVal_15 = _co.flags.nodupes; var currVal_16 = "nodupes"; var currVal_17 = "Disallow duplicate characters."; _ck(_v, 14, 0, currVal_15, currVal_16, currVal_17); }, null); }
export function View_FlagsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-flags", [], null, null, null, View_FlagsComponent_0, RenderType_FlagsComponent)), i1.ɵdid(1, 114688, null, 0, i4.FlagsComponent, [i5.ConfigService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var FlagsComponentNgFactory = i1.ɵccf("app-flags", i4.FlagsComponent, View_FlagsComponent_Host_0, {}, {}, []);
export { FlagsComponentNgFactory as FlagsComponentNgFactory };
