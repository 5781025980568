/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./item-list.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../item/item.component.ngfactory";
import * as i3 from "../item/item.component";
import * as i4 from "@angular/cdk/drag-drop";
import * as i5 from "@angular/common";
import * as i6 from "@angular/cdk/scrolling";
import * as i7 from "@angular/cdk/bidi";
import * as i8 from "../shops/shops.component.ngfactory";
import * as i9 from "../shops/shops.component";
import * as i10 from "@angular/material/dialog";
import * as i11 from "../location.service";
import * as i12 from "../item.service";
import * as i13 from "../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i14 from "@angular/material/button";
import * as i15 from "@angular/cdk/platform";
import * as i16 from "@angular/cdk/a11y";
import * as i17 from "@angular/platform-browser/animations";
import * as i18 from "../../../node_modules/@angular/material/icon/typings/index.ngfactory";
import * as i19 from "@angular/material/icon";
import * as i20 from "../item-adder/item-adder.component.ngfactory";
import * as i21 from "../item-adder/item-adder.component";
import * as i22 from "./item-list.component";
var styles_ItemListComponent = [i0.styles];
var RenderType_ItemListComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ItemListComponent, data: {} });
export { RenderType_ItemListComponent as RenderType_ItemListComponent };
function View_ItemListComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "td", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-item", [], null, null, null, i2.View_ItemComponent_0, i2.RenderType_ItemComponent)), i1.ɵdid(2, 114688, null, 0, i3.ItemComponent, [], { item: [0, "item"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.items[_v.parent.context.$implicit]; _ck(_v, 2, 0, currVal_0); }, null); }
function View_ItemListComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 16777216, null, null, 19, "tr", [["cdkDrag", ""], ["class", "cdk-drag"]], [[2, "cdk-drag-dragging", null]], null, null, null, null)), i1.ɵprd(6144, null, i4.ɵa, null, [i4.CdkDrag]), i1.ɵdid(2, 4341760, [[1, 4]], 3, i4.CdkDrag, [i1.ElementRef, [3, i4.CDK_DROP_LIST_CONTAINER], i5.DOCUMENT, i1.NgZone, i1.ViewContainerRef, i6.ViewportRuler, i4.DragDropRegistry, i4.CDK_DRAG_CONFIG, [2, i7.Directionality]], null, null), i1.ɵqud(603979776, 2, { _handles: 1 }), i1.ɵqud(335544320, 3, { _previewTemplate: 0 }), i1.ɵqud(335544320, 4, { _placeholderTemplate: 0 }), (_l()(), i1.ɵand(0, null, null, 1, null, View_ItemListComponent_3)), i1.ɵdid(7, 16384, [[3, 4]], 0, i4.CdkDragPreview, [i1.TemplateRef], null, null), (_l()(), i1.ɵeld(8, 0, null, null, 2, "td", [["class", "item-cell"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 1, "app-item", [], null, null, null, i2.View_ItemComponent_0, i2.RenderType_ItemComponent)), i1.ɵdid(10, 114688, null, 0, i3.ItemComponent, [], { item: [0, "item"] }, null), (_l()(), i1.ɵeld(11, 0, null, null, 2, "td", [["class", "item-cell"]], null, null, null, null, null)), (_l()(), i1.ɵeld(12, 0, null, null, 1, "app-shops", [], null, null, null, i8.View_ShopsComponent_0, i8.RenderType_ShopsComponent)), i1.ɵdid(13, 114688, null, 0, i9.ShopsComponent, [i10.MatDialog, i11.LocationService, i12.ItemService], { itemId: [0, "itemId"] }, null), (_l()(), i1.ɵeld(14, 0, null, null, 5, "td", [["class", "item-cell"]], null, null, null, null, null)), (_l()(), i1.ɵeld(15, 0, null, null, 4, "button", [["mat-icon-button", ""]], [[8, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.deleteRecord(_v.context.index) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i13.View_MatButton_0, i13.RenderType_MatButton)), i1.ɵdid(16, 180224, null, 0, i14.MatButton, [i1.ElementRef, i15.Platform, i16.FocusMonitor, [2, i17.ANIMATION_MODULE_TYPE]], null, null), (_l()(), i1.ɵeld(17, 0, null, 0, 2, "mat-icon", [["class", "mat-icon"], ["role", "img"]], [[2, "mat-icon-inline", null]], null, null, i18.View_MatIcon_0, i18.RenderType_MatIcon)), i1.ɵdid(18, 9158656, null, 0, i19.MatIcon, [i1.ElementRef, i19.MatIconRegistry, [8, null], [2, i19.MAT_ICON_LOCATION]], null, null), (_l()(), i1.ɵted(-1, 0, ["delete_forever"])), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.items[_v.context.$implicit]; _ck(_v, 10, 0, currVal_1); var currVal_2 = _v.context.$implicit; _ck(_v, 13, 0, currVal_2); _ck(_v, 18, 0); }, function (_ck, _v) { var currVal_0 = (i1.ɵnov(_v, 2)._hasStartedDragging && i1.ɵnov(_v, 2)._isDragging()); _ck(_v, 0, 0, currVal_0); var currVal_3 = (i1.ɵnov(_v, 16).disabled || null); var currVal_4 = (i1.ɵnov(_v, 16)._animationMode === "NoopAnimations"); _ck(_v, 15, 0, currVal_3, currVal_4); var currVal_5 = i1.ɵnov(_v, 18).inline; _ck(_v, 17, 0, currVal_5); }); }
function View_ItemListComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "table", [["cdkDropList", ""], ["class", "cdk-drop-list"]], [[8, "id", 0], [2, "cdk-drop-list-dragging", null]], [[null, "cdkDropListDropped"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("cdkDropListDropped" === en)) {
        var pd_0 = (_co.drop($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵprd(6144, null, i4.CDK_DROP_LIST_CONTAINER, null, [i4.CdkDropList]), i1.ɵdid(2, 212992, null, 1, i4.CdkDropList, [i1.ElementRef, i4.DragDropRegistry, i1.ChangeDetectorRef, [2, i7.Directionality], [3, i4.CdkDropListGroup]], null, { dropped: "cdkDropListDropped" }), i1.ɵqud(603979776, 1, { _draggables: 1 }), i1.ɵprd(256, null, i4.CdkDropListGroup, undefined, []), (_l()(), i1.ɵeld(5, 0, null, null, 2, "tbody", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ItemListComponent_2)), i1.ɵdid(7, 278528, null, 0, i5.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; _ck(_v, 2, 0); var currVal_2 = _co.selectedItems; _ck(_v, 7, 0, currVal_2); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 2).id; var currVal_1 = i1.ɵnov(_v, 2)._dragging; _ck(_v, 0, 0, currVal_0, currVal_1); }); }
export function View_ItemListComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-item-adder", [], null, null, null, i20.View_ItemAdderComponent_0, i20.RenderType_ItemAdderComponent)), i1.ɵdid(1, 114688, null, 0, i21.ItemAdderComponent, [i12.ItemService], null, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ItemListComponent_1)), i1.ɵdid(3, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; _ck(_v, 1, 0); var currVal_0 = ((_co.items && _co.selectedItems) && _co.foundItems); _ck(_v, 3, 0, currVal_0); }, null); }
export function View_ItemListComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-item-list", [], null, null, null, View_ItemListComponent_0, RenderType_ItemListComponent)), i1.ɵdid(1, 114688, null, 0, i22.ItemListComponent, [i12.ItemService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ItemListComponentNgFactory = i1.ɵccf("app-item-list", i22.ItemListComponent, View_ItemListComponent_Host_0, {}, {}, []);
export { ItemListComponentNgFactory as ItemListComponentNgFactory };
