/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./boss-tracker.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../boss/boss.component.ngfactory";
import * as i3 from "../boss/boss.component";
import * as i4 from "@angular/common";
import * as i5 from "./boss-tracker.component";
import * as i6 from "../boss.service";
var styles_BossTrackerComponent = [i0.styles];
var RenderType_BossTrackerComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_BossTrackerComponent, data: {} });
export { RenderType_BossTrackerComponent as RenderType_BossTrackerComponent };
function View_BossTrackerComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "boss"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.selectBoss(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "app-boss", [], [[8, "id", 0]], null, null, i2.View_BossComponent_0, i2.RenderType_BossComponent)), i1.ɵdid(2, 114688, null, 0, i3.BossComponent, [], { boss: [0, "boss"], state: [1, "state"] }, null), i1.ɵpid(131072, i4.AsyncPipe, [i1.ChangeDetectorRef]), i1.ɵpid(131072, i4.AsyncPipe, [i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_1 = i1.ɵunv(_v, 2, 0, i1.ɵnov(_v, 3).transform(_co.bosses$))[_v.context.$implicit]; var currVal_2 = i1.ɵunv(_v, 2, 1, i1.ɵnov(_v, 4).transform(_co.bossesFound$))[_v.context.$implicit]; _ck(_v, 2, 0, currVal_1, currVal_2); }, function (_ck, _v) { var currVal_0 = i1.ɵinlineInterpolate(1, "", _v.context.$implicit, ""); _ck(_v, 1, 0, currVal_0); }); }
function View_BossTrackerComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "tracker"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 2, null, View_BossTrackerComponent_2)), i1.ɵdid(2, 278528, null, 0, i4.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), i1.ɵpid(131072, i4.AsyncPipe, [i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 2, 0, i1.ɵnov(_v, 3).transform(_co.bossOrder$)); _ck(_v, 2, 0, currVal_0); }, null); }
export function View_BossTrackerComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 3, null, View_BossTrackerComponent_1)), i1.ɵdid(1, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), i1.ɵpid(131072, i4.AsyncPipe, [i1.ChangeDetectorRef]), i1.ɵpid(131072, i4.AsyncPipe, [i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = (i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform(_co.bosses$)) && i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 3).transform(_co.bossOrder$))); _ck(_v, 1, 0, currVal_0); }, null); }
export function View_BossTrackerComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-boss-tracker", [], null, null, null, View_BossTrackerComponent_0, RenderType_BossTrackerComponent)), i1.ɵdid(1, 114688, null, 0, i5.BossTrackerComponent, [i6.BossService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var BossTrackerComponentNgFactory = i1.ɵccf("app-boss-tracker", i5.BossTrackerComponent, View_BossTrackerComponent_Host_0, {}, { select: "select" }, []);
export { BossTrackerComponentNgFactory as BossTrackerComponentNgFactory };
