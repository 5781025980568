<mat-sidenav-container class="app-sidenav-container">
    <mat-sidenav
        mode="side"
        class="app-sidenav mat-elevation-z8"
        opened
    >
        <div class="sidenav">
            <div class="broadcast">
                <div class="selectables">
                    <app-character-tracker (select)="characterEvent($event)"></app-character-tracker>
                    <div class="vert-sep"></div>
                    <app-key-item-tracker (select)="keyItemEvent($event)"></app-key-item-tracker>
                    <div class="vert-sep"></div>
                    <app-boss-tracker (select)="bossEvent($event)"></app-boss-tracker>
                </div>
            </div>
            <div class="quick-actions">
                <button
                    mat-icon-button
                    matTooltip="Items"
                    (click)="goItems()"
                    id="items"
                >
                    <mat-icon>assignment</mat-icon>
                </button>
                <button
                    mat-icon-button
                    matTooltip="Flags"
                    (click)="goFlags()"
                    id="flags"
                >
                    <mat-icon>flag</mat-icon>
                </button>
                <button
                    mat-icon-button
                    matTooltip="Help"
                    (click)="goHelp()"
                    id="help"
                >
                    <mat-icon>help</mat-icon>
                </button>
            </div>
            <div class="locations">
                <app-location-tracker
                    [keyItemEvents]="keyItemEvents"
                    [characterEvents]="characterEvents"
                    [bossEvents]="bossEvents"
                ></app-location-tracker>
            </div>
            <div class="actions">
                <button
                    mat-raised-button
                    (click)="reset()"
                    color="warn"
                >Reset</button>
            </div>

        </div>
    </mat-sidenav>
    <mat-sidenav-content class="app-sidenav-content">
        <mat-toolbar
            color="primary"
            class="mat-elevation-z4"
        >
            <span>{{navTitle}}</span>
            <span class="toolbar-spacer"></span>
            <button
                mat-icon-button
                [matMenuTriggerFor]="menu"
            >
                <mat-icon>menu</mat-icon>
            </button>
            <mat-menu #menu="matMenu">
                <button
                    mat-menu-item
                    (click)="goItems()"
                >
                    <mat-icon>assignment</mat-icon>
                    <span>Items List</span>
                </button>
                <button
                    mat-menu-item
                    (click)="goFlags()"
                >
                    <mat-icon>flags</mat-icon>
                    <span>Flags</span>
                </button>
                <button
                    mat-menu-item
                    (click)="goHelp()"
                >
                    <mat-icon>help</mat-icon>
                    <span>Help</span>
                </button>
            </mat-menu>
        </mat-toolbar>
        <div class="content">
            <router-outlet></router-outlet>
        </div>
    </mat-sidenav-content>
</mat-sidenav-container>