<section class="mat-typography">
  <div class="help">
    <div class="top">
      <div class="keys">
        <mat-icon>arrow_back</mat-icon> Characters, Key Items, and Bosses.
      </div>
      <div class="spacer"></div>
      <div class="actions">
        <mat-icon>arrow_upward</mat-icon>
        <br />
        actions
      </div>
    </div>
    <div>
      <h2>Welcome to Cid's Workshop: the FE ÜberTracker!</h2>
      <p>This tracker aims to be an all in one tracker for
        <a href="http://ff4fe.com/">Final Fantasy IV: Free Enterprise</a>.
        Currently we support:
      </p>
      <ul>
        <li>Characters, key items, and bosses</li>
        <li>Location and points of interest availability</li>
        <li>Shops with a configurable set of items</li>
        <li>Trapped Chests</li>
        <li>Nk, Nc, Kq, Km, Kt, and -nodupes flags</li>
        <li>Boss spot stats</li>
      </ul>
      <p>
        More features are planned. See our <a href="https://github.com/konkers/cids-workshop/issues?q=is%3Aissue+is%3Aopen+label%3Aenhancement">enhancements
          section on GitHub</a>
      </p>
      <p>
        For basic character, boss, and key item tracking, chose a location on
        the left, then click on the character, boss, item at the top. The
        tracker will record what you found as well as where you found it. As
        you find key items, more locations and points of interest will unlock.
        Try selecting the <b>Magma Key</b> as your key item at
        <b>Into at Baron</b> and see how all the underworld locations unlock.
      </p>
      <p>
        For more advanced uses (shop items, flags, etc.) check out the
        <b>actions</b> menu at the top right.
      </p>
      <p>If you find any problems or have feature requests, feel free to
        <a href="https://github.com/konkers/cids-workshop/issues">
          file a PR on GitHub</a> or find me on Discord: konkers#0811.
      </p>
      <p>Shout outs to:</p>
      <ul>
        <li>Narcodis who's <a href="http://ff4fe-tracker.enewey.com/">
            tracker</a> provided inspiration for this one and created many of
          the location sprites.
        </li>
        <li>
          SchalaKitty who made the key item and boss sprites.
        </li>
        <li>
          pidgezero for inspiring <a href="http://pidgezero.one/tracker/shoptracker.html">
            shop tracking</a>.
        </li>
        <li>
          catastrophic4 for the <a href="https://www.dropbox.com/s/z4dc8m57d7919q1/checkbox.html?dl=1">checklist
            tracker</a> from which we get trapped chest data.
        </li>
        <li>
          The FE devs and Community for providing such a great, fun game!
        </li>
      </ul>
      <p>
        If you're interested in helping out or are curious, the source is
        hosted at <a href="https://github.com/konkers/cids-workshop">GitHub</a>
      </p>

    </div>
  </div>
</section>