<p>Only a subset of flags are supported</p>

<div class="flags">
    <app-flag
        [enabled]="flags.Nc"
        (change)="change('Nc', $event)"
        flag="Nc"
        desc="No free characters."
    ></app-flag>

    <app-flag
        [enabled]="flags.Nk"
        (change)="change('Nk', $event)"
        flag="Nk"
        desc="No free key items."
    ></app-flag>

    <app-flag
        [enabled]="flags.Kq"
        (change)="change('Kq', $event)"
        flag="Kq"
        desc="Mix key-items with summon quest rewards."
    ></app-flag>

    <app-flag
        [enabled]="flags.Km"
        (change)="change('Km', $event)"
        flag="Km"
        desc="Mix key-items with moon boss rewards."
    ></app-flag>

    <app-flag
        [enabled]="flags.Kt"
        (change)="change('Kt', $event)"
        flag="Kt"
        desc="Mix key-items with trapped chests."
    ></app-flag>

    <app-flag
        [enabled]="flags.nodupes"
        (change)="change('nodupes', $event)"
        flag="nodupes"
        desc="Disallow duplicate characters."
    ></app-flag>
</div>