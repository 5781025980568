<div
  class="tracker"
  *ngIf="(keyItems$ | async) && (keyItemOrder$ | async)"
>
  <div
    class="key-item"
    *ngFor="let id of keyItemOrder$ | async"
    (click)="selectKeyItem(id)"
  >
    <app-key-item
      [id]="id"
      [keyItem]="(keyItems$ | async)[id]"
      [state]="(keyItemsFound$ |async)[id]"
    ></app-key-item>
  </div>
  <div
    *ngIf="extraActions"
    class="key-item"
    (click)="selectKeyItem('chest')"
  >
    <img src="./assets/key-items/chest.png" />
  </div>
</div>