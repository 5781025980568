import { StorageService } from "angular-webstorage-service";
import { BehaviorSubject } from "rxjs";
import { STATE_VERSION } from "./state.model";
import { ObservableData } from "./observable-data";
import * as i0 from "@angular/core";
import * as i1 from "angular-webstorage-service";
var STORAGE_KEY = "workshop.state";
var StateService = /** @class */ (function () {
    function StateService(storage) {
        this.storage = storage;
        this.stateData = this.storage.get(STORAGE_KEY) || this.defaultState();
        this.updateStateData();
        this._state = new BehaviorSubject(this.stateData);
        this.state$ = this._state.asObservable();
        var charsFound = {};
        for (var _i = 0, _a = Object.keys(this.stateData.location_info); _i < _a.length; _i++) {
            var locId = _a[_i];
            var loc = this.stateData.location_info[locId];
            for (var _b = 0, _c = Object.keys(loc.poi_states); _b < _c.length; _b++) {
                var poiId = _c[_b];
                var poi = loc.poi_states[poiId];
                if (poi.char) {
                    if (poi.char in charsFound) {
                        charsFound[poi.char] += 1;
                    }
                    else {
                        charsFound[poi.char] = 1;
                    }
                }
            }
        }
        this.charsFound = new ObservableData(charsFound);
    }
    StateService.prototype.getState = function () {
        return this.state$;
    };
    StateService.prototype.getCharactersFound = function () {
        return this.charsFound.data$;
    };
    StateService.prototype.store = function () {
        console.log("saving state", this.stateData);
        this._state.next(this.stateData);
        this.storage.set(STORAGE_KEY, this.stateData);
    };
    StateService.prototype.updateFoundItems = function (foundItems) {
        this.stateData.found_items = foundItems;
        this.store();
    };
    StateService.prototype.slotEmpty = function (loc, slot) {
        for (var _i = 0, _a = Object.keys(this.stateData.key_items); _i < _a.length; _i++) {
            var id = _a[_i];
            var ki = this.stateData.key_items[id];
            if (ki.slot === slot && ki.location === loc) {
                return false;
            }
        }
        return true;
    };
    StateService.prototype.recordItemFound = function (loc, slot, field) {
        if (this.stateData.location_info[loc] &&
            this.stateData.location_info[loc][field][slot] === true) {
            delete this.stateData.location_info[loc][field][slot];
        }
        else {
            this.stateData.location_info[loc][field][slot] = true;
        }
    };
    StateService.prototype.recordKeyItem = function (key, type, loc, slot) {
        if (key === undefined) {
            return;
        }
        // Ensure we have location info for this location.
        if (!this.stateData.location_info[loc]) {
            this.stateData.location_info[loc] = {
                poi_states: {},
                poi_found_item: {},
                chest_found_item: {}
            };
        }
        if (key === "chest") {
            switch (type) {
                case "boss":
                    this.recordItemFound(loc, slot, "poi_found_item");
                    break;
                case "trapped":
                    this.recordItemFound(loc, slot, "chest_found_item");
                    break;
            }
            this.store();
        }
        else if (!(key in this.stateData.key_items)) {
            this.stateData.key_items[key] = { location: loc, slot: slot, type: type };
            this.store();
        }
    };
    StateService.prototype.unrecordKeyItem = function (key) {
        delete this.stateData.key_items[key];
        this.store();
    };
    StateService.prototype.ensureLocationPoi = function (locId, poiIndex) {
        if (!this.stateData.location_info[locId]) {
            this.stateData.location_info[locId] = {
                poi_states: {},
                poi_found_item: {},
                chest_found_item: {}
            };
        }
        var loc = this.stateData.location_info[locId];
        if (!loc.poi_states[poiIndex]) {
            loc.poi_states[poiIndex] = {};
        }
    };
    StateService.prototype.recordCharacter = function (char, locId, slotId, allowDupes) {
        if (!allowDupes &&
            char in this.charsFound.data &&
            this.charsFound.data[char] > 0) {
            return;
        }
        this.ensureLocationPoi(locId, slotId);
        var slot = this.stateData.location_info[locId].poi_states[slotId];
        if (char && !slot.char) {
            slot.char = char;
            this.store();
            if (char in this.charsFound.data) {
                this.charsFound.data[char] += 1;
            }
            else {
                this.charsFound.data[char] = 1;
            }
            this.charsFound.next();
        }
    };
    StateService.prototype.unrecordCharacter = function (char, loc, slot) {
        delete this.stateData.location_info[loc].poi_states[slot].char;
        this.store();
        this.charsFound.data[char] -= 1;
        this.charsFound.next();
    };
    StateService.prototype.recordBoss = function (boss, type, loc, slot) {
        if (!(boss in this.stateData.bosses)) {
            this.stateData.bosses[boss] = { location: loc, slot: slot, type: type };
            this.store();
        }
    };
    StateService.prototype.unrecordBoss = function (boss) {
        delete this.stateData.bosses[boss];
        this.store();
    };
    StateService.prototype.recordTrappedChest = function (locId, chest, found) {
        if (!(locId in this.stateData.trapped_chests)) {
            this.stateData.trapped_chests[locId] = {};
        }
        this.stateData.trapped_chests[locId][chest] = found;
        this.store();
    };
    StateService.prototype.defaultState = function () {
        return {
            version: STATE_VERSION,
            found_items: {},
            key_items: {},
            bosses: {},
            location_info: {},
            trapped_chests: {}
        };
    };
    StateService.prototype.checkStateField = function (field) {
        var defaults = this.defaultState();
        if (!(field in this.stateData)) {
            this.stateData[field] = defaults[field];
        }
    };
    StateService.prototype.updateStateData = function () {
        if (this.stateData.version <= 3) {
            this.stateData = this.defaultState();
            return;
        }
        this.checkStateField("found_items");
        this.checkStateField("key_items");
        this.checkStateField("chars");
        this.checkStateField("bosses");
        this.checkStateField("location_info");
        this.checkStateField("trapped_chests");
        this.stateData.version = STATE_VERSION;
    };
    StateService.prototype.reset = function () {
        this.stateData = this.defaultState();
        this.store();
        this.charsFound.data = {};
        this.charsFound.next();
    };
    StateService.ngInjectableDef = i0.defineInjectable({ factory: function StateService_Factory() { return new StateService(i0.inject(i1.SESSION_STORAGE)); }, token: StateService, providedIn: "root" });
    return StateService;
}());
export { StateService };
