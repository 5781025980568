import { Routes } from "@angular/router";
import { ItemListComponent } from "./item-list/item-list.component";
import { ShopItemPickerComponent } from "./shop-item-picker/shop-item-picker.component";
import { LocationDetailComponent } from "./location-detail/location-detail.component";
import { FlagsComponent } from "./flags/flags.component";
import { HelpComponent } from "./help/help.component";
var appRoutes = [
    { path: "loc/:loc", component: LocationDetailComponent },
    { path: "shop/:loc/:type", component: ShopItemPickerComponent },
    { path: "flags", component: FlagsComponent },
    { path: "items", component: ItemListComponent },
    { path: "items", component: HelpComponent },
    { path: "**", component: HelpComponent }
];
var ɵ0 = { hasBackdrop: false };
var AppModule = /** @class */ (function () {
    function AppModule() {
    }
    return AppModule;
}());
export { AppModule };
export { ɵ0 };
