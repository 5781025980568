import { HttpClient } from "@angular/common/http";
import { IndexedData } from "./indexed-data";
import { StateService } from "./state.service";
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "./state.service";
var CharacterService = /** @class */ (function () {
    function CharacterService(http, stateService) {
        this.http = http;
        this.stateService = stateService;
        this.chars = (new IndexedData(this.http, "./assets/data/chars.json"));
    }
    CharacterService.prototype.getCharacters = function () {
        return this.chars.data$;
    };
    CharacterService.prototype.getCharacterOrder = function () {
        return this.chars.order$;
    };
    CharacterService.prototype.getCharactersFound = function () {
        return this.stateService.getCharactersFound();
    };
    CharacterService.ngInjectableDef = i0.defineInjectable({ factory: function CharacterService_Factory() { return new CharacterService(i0.inject(i1.HttpClient), i0.inject(i2.StateService)); }, token: CharacterService, providedIn: "root" });
    return CharacterService;
}());
export { CharacterService };
