/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./item.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../icon/icon.component.ngfactory";
import * as i3 from "../icon/icon.component";
import * as i4 from "./item.component";
var styles_ItemComponent = [i0.styles];
var RenderType_ItemComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ItemComponent, data: {} });
export { RenderType_ItemComponent as RenderType_ItemComponent };
export function View_ItemComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-icon", [], null, null, null, i2.View_IconComponent_0, i2.RenderType_IconComponent)), i1.ɵdid(1, 114688, null, 0, i3.IconComponent, [], { type: [0, "type"] }, null), (_l()(), i1.ɵted(2, null, ["", ""]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵinlineInterpolate(1, "", _co.item.icon, ""); _ck(_v, 1, 0, currVal_0); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.item.name; _ck(_v, 2, 0, currVal_1); }); }
export function View_ItemComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-item", [], null, null, null, View_ItemComponent_0, RenderType_ItemComponent)), i1.ɵdid(1, 114688, null, 0, i4.ItemComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ItemComponentNgFactory = i1.ɵccf("app-item", i4.ItemComponent, View_ItemComponent_Host_0, { item: "item" }, {}, []);
export { ItemComponentNgFactory as ItemComponentNgFactory };
