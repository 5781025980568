/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./key-item-tracker.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../key-item/key-item.component.ngfactory";
import * as i3 from "../key-item/key-item.component";
import * as i4 from "@angular/common";
import * as i5 from "./key-item-tracker.component";
import * as i6 from "../key-item.service";
var styles_KeyItemTrackerComponent = [i0.styles];
var RenderType_KeyItemTrackerComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_KeyItemTrackerComponent, data: {} });
export { RenderType_KeyItemTrackerComponent as RenderType_KeyItemTrackerComponent };
function View_KeyItemTrackerComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "key-item"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.selectKeyItem(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "app-key-item", [], [[8, "id", 0]], null, null, i2.View_KeyItemComponent_0, i2.RenderType_KeyItemComponent)), i1.ɵdid(2, 114688, null, 0, i3.KeyItemComponent, [], { keyItem: [0, "keyItem"], state: [1, "state"] }, null), i1.ɵpid(131072, i4.AsyncPipe, [i1.ChangeDetectorRef]), i1.ɵpid(131072, i4.AsyncPipe, [i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_1 = i1.ɵunv(_v, 2, 0, i1.ɵnov(_v, 3).transform(_co.keyItems$))[_v.context.$implicit]; var currVal_2 = i1.ɵunv(_v, 2, 1, i1.ɵnov(_v, 4).transform(_co.keyItemsFound$))[_v.context.$implicit]; _ck(_v, 2, 0, currVal_1, currVal_2); }, function (_ck, _v) { var currVal_0 = _v.context.$implicit; _ck(_v, 1, 0, currVal_0); }); }
function View_KeyItemTrackerComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "key-item"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.selectKeyItem("chest") !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "img", [["src", "./assets/key-items/chest.png"]], null, null, null, null, null))], null, null); }
function View_KeyItemTrackerComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [["class", "tracker"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 2, null, View_KeyItemTrackerComponent_2)), i1.ɵdid(2, 278528, null, 0, i4.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), i1.ɵpid(131072, i4.AsyncPipe, [i1.ChangeDetectorRef]), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_KeyItemTrackerComponent_3)), i1.ɵdid(5, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 2, 0, i1.ɵnov(_v, 3).transform(_co.keyItemOrder$)); _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.extraActions; _ck(_v, 5, 0, currVal_1); }, null); }
export function View_KeyItemTrackerComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 3, null, View_KeyItemTrackerComponent_1)), i1.ɵdid(1, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), i1.ɵpid(131072, i4.AsyncPipe, [i1.ChangeDetectorRef]), i1.ɵpid(131072, i4.AsyncPipe, [i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = (i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform(_co.keyItems$)) && i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 3).transform(_co.keyItemOrder$))); _ck(_v, 1, 0, currVal_0); }, null); }
export function View_KeyItemTrackerComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-key-item-tracker", [], null, null, null, View_KeyItemTrackerComponent_0, RenderType_KeyItemTrackerComponent)), i1.ɵdid(1, 114688, null, 0, i5.KeyItemTrackerComponent, [i6.KeyItemService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var KeyItemTrackerComponentNgFactory = i1.ɵccf("app-key-item-tracker", i5.KeyItemTrackerComponent, View_KeyItemTrackerComponent_Host_0, { extraActions: "extraActions" }, { select: "select" }, []);
export { KeyItemTrackerComponentNgFactory as KeyItemTrackerComponentNgFactory };
