/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./location-tracker.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../location-summary/location-summary.component.ngfactory";
import * as i3 from "../location-summary/location-summary.component";
import * as i4 from "../location.service";
import * as i5 from "@angular/common";
import * as i6 from "ngx-if-empty-or-has-items";
import * as i7 from "./location-tracker.component";
import * as i8 from "@angular/router";
var styles_LocationTrackerComponent = [i0.styles];
var RenderType_LocationTrackerComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_LocationTrackerComponent, data: {} });
export { RenderType_LocationTrackerComponent as RenderType_LocationTrackerComponent };
function View_LocationTrackerComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "location"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.gotoLoc(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-location-summary", [], [[8, "id", 0]], null, null, i2.View_LocationSummaryComponent_0, i2.RenderType_LocationSummaryComponent)), i1.ɵdid(2, 114688, null, 0, i3.LocationSummaryComponent, [i4.LocationService], { locId: [0, "locId"], selected: [1, "selected"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _v.context.$implicit; var currVal_2 = (_co.selectedLoc === _v.context.$implicit); _ck(_v, 2, 0, currVal_1, currVal_2); }, function (_ck, _v) { var currVal_0 = i1.ɵinlineInterpolate(1, "", _v.context.$implicit, ""); _ck(_v, 1, 0, currVal_0); }); }
function View_LocationTrackerComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "section", [["class", "mat-typography"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [["class", "locations"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_LocationTrackerComponent_2)), i1.ɵdid(3, 278528, null, 0, i5.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.locOrder; _ck(_v, 3, 0, currVal_0); }, null); }
export function View_LocationTrackerComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_LocationTrackerComponent_1)), i1.ɵdid(1, 16384, null, 0, i6.NgxIfNotEmptyDirective, [i1.ViewContainerRef, i1.TemplateRef], { ngxIfNotEmpty: [0, "ngxIfNotEmpty"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.locs; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_LocationTrackerComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-location-tracker", [], null, null, null, View_LocationTrackerComponent_0, RenderType_LocationTrackerComponent)), i1.ɵdid(1, 114688, null, 0, i7.LocationTrackerComponent, [i8.Router, i4.LocationService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var LocationTrackerComponentNgFactory = i1.ɵccf("app-location-tracker", i7.LocationTrackerComponent, View_LocationTrackerComponent_Host_0, { keyItemEvents: "keyItemEvents", characterEvents: "characterEvents", bossEvents: "bossEvents" }, {}, []);
export { LocationTrackerComponentNgFactory as LocationTrackerComponentNgFactory };
